<template>
    <PatientsDatatable :psycho-email='psychoEmail' :records='records' :events='events' @refresh='refreshList'/>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('Psycho/Patients');
const authnStore = createNamespacedHelpers('Authn');

import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import PatientsDatatable from '../shared/PatientsDatatable.vue';

export default {
    components: {
        PatientsDatatable,
    },
    data() {
        return {
            id: 0,
            filters: null,
            showAppointments: false,
            appointmentsData: [],
            showUserInterview: false,
            userInterview: [],
            events: [],
        };
    },
    computed: {
        ...mapGetters(['rows', 'loading', 'psycho']),
        ...authnStore.mapGetters(['email']),
        psychoEmail() {
            if (this.email) return this.email;
            return '...';
        },
        records() {
            if (!this.rows) return [];
            return this.rows.map(row => {
                let paid = 0;
                const first_date = row['first_appointment'] ? this.localeTs2Date(row['first_appointment']['book_date']) : '-';
                let next_app = '-';
                if (row['paid_appointments']) {
                    paid = row['paid_appointments'].filter(r => r['status'] === 'active').length;
                    const now = new Date().getTime();
                    let i = 0;
                    while (i < row['paid_appointments'].length) {
                        const app = row['paid_appointments'][i];
                        i++;
                        if (app['status'] !== 'active') {
                            continue;
                        }
                        if (next_app === '-') {
                            next_app = app['book_date'];
                        }
                        const d = new Date(app['book_date']).getTime();
                        if (d > now) {
                            next_app = app['book_date'];
                            break;
                        }
                    }
                }
                return {
                    id: row['patient']['id'],
                    sparta_id: row['patient']['sparta_id'],
                    paid: paid,
                    first_appointment: first_date,
                    email: row['patient']['email'],
                    next_app: next_app !== '-' ? this.localeTs2Date(next_app) : next_app,
                };
            });
        },
    },
    mounted() {
        this.fetchPsychologist();
        this.refreshList();
    },
    watch: {
        psycho(n) {
            if (n !== null && n['id']) {
                this.refreshEvents(n['id']);
            }
        },
    },
    methods: {
        ...mapActions(['fetchPatientList', 'fetchPatientAnswers', 'fetchCalendlyEvents', 'fetchPsycho']),
        showAppData(uid) {
            this.appointmentsData = [];
            if (!this.rows) return;

            const userData = this.rows.filter(r => r['patient']['id'] === uid);
            if (userData.length === 0) return;
            let n = 0;
            this.appointmentsData = userData[0]['paid_appointments'].map(r => {
                return {
                    ...r,
                    id: n++,
                };
            });
            this.showAppointments = true;
        },
        dismissAppData() {
            this.appointmentsData = [];
            this.showAppointments = false;
        },
        async refreshList() {
            if (!this.email) {
                this.ShowError('Errore', 'Email utente mancante');
                return;
            }
            this.fetchPatientList({
                id: this.id,
                email: this.email,
            }).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        async refreshEvents(id) {
            this.fetchCalendlyEvents({
                id: id,
            }).then(res => {
                this.events = res;
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        async fetchPsychologist() {
            if (!this.email) {
                this.ShowError('Errore', 'Email utente mancante');
                return;
            }
            this.fetchPsycho({
                email: this.email,
            }).then().catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Notifications,
        Navigations,
    ],
};
</script>
